import { Typography } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { titleBlack } from "../colors";
import i18n from "../i18n";

export const missingCreds = () => {
    return (
        !localStorage.getItem('refresh') ||
        !localStorage.getItem('email') ||
        !localStorage.getItem('firstName') ||
        !localStorage.getItem('lastName') ||
        !localStorage.getItem('profileImageUrl')
    )
}

export const destroySession = () => {
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('profileImageUrl');
}

export const toastError = (message) => toast.error(<Typography style={{ color: titleBlack }} variant='h4'>{i18n.t(message)}</Typography>)
export const toastSuccess = (message) => toast.success(<Typography style={{ color: titleBlack }} variant='h4'>{i18n.t(message)}</Typography>)