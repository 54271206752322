import axios from "axios";
import { SET_GRAPH, SET_METADATA, SET_PROCESSED_IMAGES } from "../actions/types";
import { put } from "redux-saga/effects";
import { get } from 'lodash'
import { toastError, toastSuccess } from "../utils/functions";
import { apiURL } from "./settingsSagas";
import { checkSession, getAccessToken } from "./authSagas";

export const baseURL = process.env.REACT_APP_BACKEND_URL

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.message === 'Network Error') {
        checkSession()
    } else {
        return error;
    }
});

export function* getMetadataSaga() {
    try {
        const token = yield getAccessToken()
        let getMetadataResponse = yield axios.get(`${baseURL}/api/metadata`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (getMetadataResponse.status === 200) {
            yield put({ type: SET_GRAPH, data: getMetadataResponse.data.graph.data });
            yield put({ type: SET_METADATA, data: getMetadataResponse.data.data });
        }

    } catch (e) {
        toastError(get(e, 'message', 'somethingWentWrong'))
    }

}

export function* getProcessedImagesSaga(params) {
    try {

        if (!params.params.imageId) {
            yield put({
                type: SET_PROCESSED_IMAGES, data: {
                    preprocessedImageUrl: '',
                    imageUrl: ''
                }
            });
            return
        }
        const apiKey = localStorage.getItem('apiKey')
        let getProcessedImagesResponse = yield axios.get(`${apiURL}result?presigned=true&original=true&imageId=${params.params.imageId}`, {
            headers: {
                'X-API-Key': apiKey,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                "Authorization": ""
            }
        });

        if (getProcessedImagesResponse.data.status === 'pending') {
            toastSuccess("Image is processing")
        }
        if (getProcessedImagesResponse.data.status === 'completed') {
            yield put({
                type: SET_PROCESSED_IMAGES, data: {
                    preprocessedImageUrl: getProcessedImagesResponse.data.preprocessedImage,
                    imageUrl: getProcessedImagesResponse.data.image
                }
            });
        } else {
            yield put({
                type: SET_PROCESSED_IMAGES, data: {
                    preprocessedImageUrl: '',
                    imageUrl: ''
                }
            });
        }
    } catch (e) {
        toastError(get(e, 'response.data.detail', 'somethingWentWrong'))
        yield put({
            type: SET_PROCESSED_IMAGES, data: [{
                preprocessedImageUrl: '',
                imageUrl: ''
            }]
        });
    }
}
