import { useEffect } from "react"
import { Auth } from "aws-amplify"

const SignOut = () => {
    useEffect(() => {
        Auth.federatedSignIn()
    }, [])
    return null
}

export default SignOut