import {combineReducers} from 'redux';
import toastReducer from "./toastReducer";
import tabsReducer from "./tabsReducer";
import settingsReducer from "./settingsReducer";
import controllerReducer from "./controllerReducer";
import reportReducer from "./reportReducer";
import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import cameraStreamsReducer from "./cameraStreamsReducer";

const allReducers = combineReducers({
    toast: toastReducer,
    tabs: tabsReducer,
    settings: settingsReducer,
    controller: controllerReducer,
    report: reportReducer,
    auth: authReducer,
    modal:modalReducer,
    cameraStreams: cameraStreamsReducer,
});

export default allReducers;
