export const ColorHex = {
    "Black": "#000000",
    "Blue": "#0000FF",
    "Brown": "#A52A2A",
    "Cyan": "#00FFFF",
    "Gold": "#FFD700",
    "Green": "#008000",
    "Gray": "#808080",
    "Orange": "#FFA500",
    "Pink": "#FFC0CB",
    "Purple": "#800080",
    "Red": "#FF0000",
    "White": "#FFFFFF",
    "Yellow": "#FFFF00",
}