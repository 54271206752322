import { SHOW_SELECTED_TAB } from "../actions/types";

const initialState = {
    selectedTab: null,
};

const tabsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SHOW_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.params,
            }

        default:
            return state;
    }
}

export default tabsReducer