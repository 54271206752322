export function camelCaseToWords(s) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function toSnakeCase(str) {
    return str &&
        str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('_');
}

export function wordToCamelCase(s) {
    return s.charAt(0).toLowerCase() + s.slice(1);
}

export function queryStringToObject(queryString) {
    const pairs = queryString.substring(1).split('&');
    const array = pairs.map((el) => {
        const parts = el.split('=');
        return parts;
    });
    return Object.fromEntries(array);
}

export function changeUrlToQuery(s){
    return s.replace(/\//gi,"%2F").replace(/:/gi,"%3A")
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}