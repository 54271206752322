import { CLEAR_PROCESSED_IMAGES, GET_PROCESSED_IMAGES, SET_GRAPH, SET_METADATA, SET_PROCESSED_IMAGES } from "../actions/types";

const initialState = {
    imageCount: 0,
    objectCount: 0,
    costAccrued: 0,
    processedImages: null,
    processedImagesLoading: false,
    graph: [],
};

const reportReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_METADATA:

            return {
                ...state,
                imageCount: action.data.imageCount,
                objectCount: action.data.objectCount,
                costAccrued: action.data.costAccrued,
            }

        case SET_GRAPH:

            return {
                ...state,
                graph: action.data,
            }

        case GET_PROCESSED_IMAGES:

            return {
                ...state,
                processedImagesLoading: true,
            }

        case SET_PROCESSED_IMAGES:
            return {
                ...state,
                processedImagesLoading: false,
                processedImages: [action.data],
            }

        case CLEAR_PROCESSED_IMAGES:
            return {
                ...state,
                processedImages: []
            }
        default:
            return state;
    }
}

export default reportReducer