import { baseURL } from "./reportSagas";
import axios from "axios";
import { SET_CAMERAS, SET_PROJECTS } from "../actions/types";
import { put } from "redux-saga/effects";
import { toastError } from "../utils/functions";
import { checkSession, getAccessToken } from "./authSagas";

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.message === 'Network Error') {
        checkSession()
    } else {
        return error;
    }
});

export function* getProjectsSaga() {
    try {
        const token = yield getAccessToken()
        let getProjectsResponse = yield axios.get(`${baseURL}/api/project`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (getProjectsResponse.status === 200) {
            yield put({ type: SET_PROJECTS, data: getProjectsResponse?.data?.data });
        }

        else {
            yield put({ type: SET_PROJECTS, data: [] });
        }
    }
    catch (e) {
        toastError('somethingWentWrong')
        yield put({ type: SET_PROJECTS, data: [] });
    }
}

export function* getCamerasSaga() {
    try {
        const token = yield getAccessToken()
        let getCamerasResponse = yield axios.get(`${baseURL}/api/camera`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (getCamerasResponse.status === 200) {
            const processData = getCamerasResponse?.data?.data?.map(camera => ({
                ...camera,
                settings: camera.settings ? camera.settings : {
                    objects: ['Person'],
                    color: 'Black',
                    maskType: 'blur',
                    opacity: 0.5,
                    polygons: []
                },
            }))

            yield put({ type: SET_CAMERAS, data: processData });
        }

        else {
            yield put({ type: SET_CAMERAS, data: [] });
        }
    } catch {
        toastError('somethingWentWrong')
        yield put({ type: SET_CAMERAS, data: [] });
    }
}

export function* createNewProjectSaga(params) {
}

export function* createNewCameraSaga(params) {
}
