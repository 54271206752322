import { createMuiTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import { backgroundWhite, grey50, primary400, primaryAccent, primaryActionColor, titleBlack } from "./colors";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import loadable from '@loadable/component';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { getApiKeyAction } from "./actions/authActions";
import { useEffect } from "react";
import { parseJwt } from "./utils/helper";
import SignOut from "./views/Authentication/SignOut";
import { Hub } from "aws-amplify";
import { checkSession } from "./sagas/authSagas";

const Toast = loadable(() => import('./components/Toast/Toast'));
const AppHeader = loadable(() => import('./components/AppHeader/AppHeader'));
const AddNewModal = loadable(() => import('./components/AddNewModal/AddNewModal'));
const Home = loadable(() => import('./views/Home/Home'));
const APIKey = loadable(() => import('./views/APIKey/APIKey'));
const Authentication = loadable(() => import('./views/Authentication/Authentication'));
const Contact = loadable(() => import('./views/Contact/Contact'));

const THEME = createMuiTheme({
    typography: {
        fontFamily: 'Poppins',
    },
    palette: {
        primary: {
            main: `${primaryAccent}`,
            light: `${primary400}`,
            dark: `${primary400}`,
        },
    },
    slider: {
        trackColor: "#000",
        selectionColor: "#000"
    }
});

THEME.typography.h1 = {
    fontSize: 22,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: titleBlack,
    [THEME.breakpoints.up('md')]: {
        fontSize: 24,
        fontWeight: 600,
        color: titleBlack,
    },
};
THEME.typography.h2 = {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: titleBlack,
    [THEME.breakpoints.up('md')]: {
        fontSize: 20,
        fontWeight: 600,
        color: titleBlack,
    },
};
THEME.typography.h3 = {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 16,
        fontWeight: 400,
        color: grey50,
    },
};
THEME.typography.h4 = {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 14,
        fontWeight: 400,
        color: grey50,
    },
};
THEME.typography.h5 = {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 12,
        fontWeight: 400,
        color: grey50,
    },
};
THEME.typography.h6 = {
    fontSize: 8,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 10,
        fontWeight: 500,
        color: grey50,
    },
};
THEME.typography.subtitle1 = {
    fontSize: 12,
    fontWeight: 400,
    color: primaryActionColor,
    fontFamily: 'Poppins',
    [THEME.breakpoints.up('md')]: {
        fontSize: 14,
    },
};
THEME.typography.subtitle2 = {
    fontSize: 12,
    [THEME.breakpoints.up('md')]: {
        fontSize: 12,
    },
};
THEME.typography.body1 = {
    fontSize: 10,
    fontWeight: 400,
    color: backgroundWhite,
    fontFamily: 'Poppins',
    [THEME.breakpoints.up('md')]: {
        fontSize: 12,
    },
};
THEME.typography.body2 = {
    fontSize: 12,
    fontWeight: 700,
    [THEME.breakpoints.up('md')]: {
        fontSize: 14,
    },
};

function App({ setAddNewModalOpen, getApiKey }) {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { checkingApiKey } = useSelector(state => state.auth)

    useEffect(() => {
        if (!["/sign-in", "/sign-out"].includes(pathname)) {
            checkSession();
        }
    }, [pathname]);

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                case "cognitoHostedUI":
                    console.log("Authenticated...");
                    const { email } = parseJwt(data.signInUserSession.idToken.jwtToken,);
                    localStorage.setItem('email', email);
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Error", data);
                    break;
                default:
                    break
            }
        });
    }, []);

    useEffect(() => {
        dispatch(getApiKey())
    }, [dispatch, getApiKey, pathname])

    useEffect(() => {
        const userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes("de")) {
            i18n.changeLanguage('de')
        } else {
            i18n.changeLanguage("en")
        }
    }, [])

    return (
        <I18nextProvider i18n={i18n}>
            <MuiThemeProvider theme={THEME}>
                <Toast />
                {setAddNewModalOpen ? <AddNewModal /> : <></>}
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {!checkingApiKey &&
                            <>
                                <AppHeader />
                                <Switch>
                                    <Route exact path="/" render={() => <Redirect to="/login" />} />
                                    <Route path="/home/:id" component={Home} />
                                    <Route path="/login" component={Authentication} />
                                    <Route path="/sign-out" component={SignOut} />
                                    <Route path="/contact-us" component={Contact} />
                                    <Route path="/api-key" component={APIKey} />
                                </Switch>
                            </>
                        }
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </I18nextProvider>
    );
}

const mapStateToProps = (state) => ({
    setAddNewModalOpen: state.modal.setAddNewModalOpen,
});

const mapDispatchToProps = {
    getApiKey: getApiKeyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
