import { CHECK_TOKEN_FINISH, CHECK_TOKEN_PENDING, GET_API_KEY_FINISH, GET_API_KEY_PENDING, LOG_IN_FAILURE, privateEmails, SIGN_UP_FAILURE } from "../actions/types";
import { put } from "redux-saga/effects";
import * as Amplify from 'aws-amplify';
import * as _ from 'lodash'
import axios from "axios";
import { toastError } from "../utils/functions";
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants";

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.message === 'Network Error') {
        localStorage.setItem("redirectSignOut", "false")
        checkSession()
    } else {
        return error;
    }
});

Amplify.Amplify.configure({
    Auth: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        region: process.env.REACT_APP_REGION,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
        mandatorySignIn: true,
        oauth: {
            domain: `${process.env.REACT_APP_HOSTED_UI_DOMAIN}`,
            redirectSignOut: `${process.env.REACT_APP_HOST_URL}/sign-out`,
            redirectSignIn: `${process.env.REACT_APP_HOST_URL}`,
            responseType: 'code',
            scope: ['email', 'openid']
        }
    },
});


export const checkSession = async () => {
    Amplify.Auth.currentSession()
        .then(async session => {
            if (!session.isValid()) {
                await logOutSession()
            }
        })
        .catch(async (err) => {
            window.location = `${process.env.REACT_APP_HOST_URL}/sign-out`
        });
}

export const logOutSession = async () => {
    localStorage.setItem('email', '')
    Amplify.Auth.signOut()
}

export const getAccessToken = async () => {
    try {
        const session = await Amplify.Auth.currentSession()
        if (session && session.isValid()) {
            return session.getIdToken().getJwtToken()
        } else {
            await logOutSession()
        }
    } catch (error) {
        window.location = `${process.env.REACT_APP_HOST_URL}/sign-out`
    }
}

export const baseAuthURL = process.env.REACT_APP_AUTH_URL;
export const baseURL = process.env.REACT_APP_BACKEND_URL
export function* signUpSaga(params) {
    try {
        if (privateEmails.includes(params.params.email.split("@")[1].split(".")[0])) {
            yield put({ type: SIGN_UP_FAILURE, data: { message: 'Please sign up using your company email.' } })
        }
        else {
            const { email, password } = params.params
            yield Amplify.Auth.signUp({
                username: email,
                password: password,
            })
            window.location = '/login';
        }
    }
    catch (e) {
        yield put({ type: SIGN_UP_FAILURE, data: { message: null } });
        if (e.message.includes('500')) {
            toastError('somethingWentWrong')
        }
        else if (e.message.includes('Network Error')) {
            toastError('somethingWentWrong')
        } else {
            toastError(_.get(e, 'message', 'somethingWentWrong.'))
        }
    }
}

export function* logInSaga(params) {
    try {
        const { email, password } = params.params
        const response = yield Amplify.Auth.signIn({
            username: email,
            password
        })
        localStorage.setItem(ID_TOKEN_KEY, _.get(response, 'signInUserSession.idToken.jwtToken', ""));
        localStorage.setItem(REFRESH_TOKEN_KEY, _.get(response, 'signInUserSession.refreshToken.token', ""));
        localStorage.setItem(ACCESS_TOKEN_KEY, _.get(response, 'signInUserSession.accessToken.jwtToken', ""))
        localStorage.setItem('firstName', _.get(response, 'username'));
        localStorage.setItem('lastName', '');
        window.location = '/home/camera-streams';
    }
    catch (e) {
        if (e.message.includes('404')) {
            yield put({ type: LOG_IN_FAILURE, data: { message: null } });
            toastError('notExistAccount')
        }
        else {
            yield put({ type: LOG_IN_FAILURE, data: { message: null } });
            toastError(_.get(e, 'message', 'somethingWentWrong'))
        }
    }
}

export function* apiKeySaga() {
    const apiKey = localStorage.getItem('apiKey')
    if (apiKey) {
        yield put({
            type: GET_API_KEY_FINISH
        })
        return
    }
    yield put({
        type: GET_API_KEY_PENDING
    })
    try {
        const token = yield getAccessToken()
        if (!token) {
            return
        } else {
            const response = yield axios.get(`${baseAuthURL}/apikey`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const { apikey } = response.data
            const { enabled, value, is_shared } = apikey
            if (enabled) {
                localStorage.setItem('apiKey', value)
                localStorage.setItem('apiKeyIsShared', is_shared)
            } else {
                toastError('apiKeyNotEnabled')
            }
        }

    }
    catch (e) {
        toastError('somethingWentWrong')
        if (e.message.includes('401')) {
            yield checkSession()
        }
    } finally {
        yield put({
            type: GET_API_KEY_FINISH
        })
    }
}

export function* checkToken() {
    yield put({
        type: CHECK_TOKEN_PENDING
    })
    try {
        const currentUser = yield Amplify.Auth.currentAuthenticatedUser();
        const currentSession = currentUser.signInUserSession;
        currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
            localStorage.setItem(ID_TOKEN_KEY, _.get(session, 'idToken.jwtToken', ""));
            localStorage.setItem(REFRESH_TOKEN_KEY, _.get(session, 'refreshToken.token', ""));
            localStorage.setItem(ACCESS_TOKEN_KEY, _.get(session, 'accessToken.jwtToken', ""))
        });
    } catch (error) {
        console.error(error)
    } finally {
        yield put({
            type: CHECK_TOKEN_FINISH
        })

    }

}
