import { GET_CAMERAS, GET_PROJECTS, SET_CAMERAS, SET_PROJECTS } from "../actions/types";

const initialState = {
    allProjectsLoading: false,
    allProjects: null,
    allCamerasLoading: false,
    allCameras: null,
};

const cameraStreamsReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_PROJECTS:

            return {
                ...state,
                allProjectsLoading: true,
            }

        case SET_PROJECTS:

            return {
                ...state,
                allProjectsLoading: false,
                allProjects: action.data,
            }

        case GET_CAMERAS:

            return {
                ...state,
                allCamerasLoading: true,
            }

        case SET_CAMERAS:

            return {
                ...state,
                allCamerasLoading: false,
                allCameras: action.data,
            }

        default:
            return state;
    }
}

export default cameraStreamsReducer