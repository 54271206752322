import { all, takeLatest } from 'redux-saga/effects';
import {
    ADD_REMOVE_OBJECT,
    GET_METADATA,
    GET_OPTIONS,
    REMOVE_SELECTED_SETTING,
    SAVE_SELECTED_SETTING,
    GET_SAVED_SETTINGS,
    GET_CAMERA_IDS,
    GET_PROCESSED_IMAGES,
    SIGN_UP,
    LOG_IN,
    SUBMIT_IMAGE,
    SUBMIT_IMAGE_TRIAL,
    GET_PROJECTS, CREATE_NEW_CAMERA, CREATE_NEW_PROJECT, GET_CAMERAS, SUBMIT_IMAGE_SETTING, GET_API_KEY, CHECK_TOKEN
} from "../actions/types";
import {
    addRemoveObjectSaga,
    getCameraIDsSaga,
    getOptionsSaga,
    getSavedSettingsSaga,
    removeSelectedSettingSaga,
    saveSelectedSettingSaga, submitImageSaga, submitImageSettingSaga, submitImageTrialSaga
} from "./settingsSagas";
import {
    getMetadataSaga,
    getProcessedImagesSaga
} from "./reportSagas";
import { apiKeySaga, checkToken, logInSaga, signUpSaga } from "./authSagas";
import { createNewCameraSaga, createNewProjectSaga, getCamerasSaga, getProjectsSaga } from "./cameraStreamsSagas";

export default function* root() {
    const sagaIndex = [
        yield takeLatest(ADD_REMOVE_OBJECT, addRemoveObjectSaga),
        yield takeLatest(SAVE_SELECTED_SETTING, saveSelectedSettingSaga),
        yield takeLatest(REMOVE_SELECTED_SETTING, removeSelectedSettingSaga),
        yield takeLatest(GET_OPTIONS, getOptionsSaga),

        yield takeLatest(GET_METADATA, getMetadataSaga),
        yield takeLatest(GET_SAVED_SETTINGS, getSavedSettingsSaga),
        yield takeLatest(GET_CAMERA_IDS, getCameraIDsSaga),
        yield takeLatest(GET_PROCESSED_IMAGES, getProcessedImagesSaga),

        yield takeLatest(SUBMIT_IMAGE_SETTING, submitImageSettingSaga),

        yield takeLatest(SUBMIT_IMAGE, submitImageSaga),
        yield takeLatest(SUBMIT_IMAGE_TRIAL, submitImageTrialSaga),

        yield takeLatest(SIGN_UP, signUpSaga),
        yield takeLatest(LOG_IN, logInSaga),

        yield takeLatest(GET_API_KEY, apiKeySaga),
        yield takeLatest(CHECK_TOKEN, checkToken),

        yield takeLatest(GET_PROJECTS, getProjectsSaga),
        yield takeLatest(GET_CAMERAS, getCamerasSaga),
        yield takeLatest(CREATE_NEW_PROJECT, createNewProjectSaga),
        yield takeLatest(CREATE_NEW_CAMERA, createNewCameraSaga),
    ];

    yield all(sagaIndex);
}
