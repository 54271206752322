import {
    ADD_REMOVE_OBJECT,
    ADD_REMOVE_OBJECT_SUCCESS,
    CLEAR_ALL_OBJECT,
    REMOVE_SELECTED_SETTING,
    REMOVE_SELECTED_SETTING_SUCCESS,
    SET_CAMERA_FOR_SETTINGS,
    SET_CAMERA_IDS,
    SET_OBJECT,
    SET_OPTIONS,
    SET_POLYGON_POINTS,
    SET_SAVED_SETTINGS,
    SUBMIT_IMAGE_FAILURE,
    SUBMIT_IMAGE_PENDING,
    SUBMIT_IMAGE_SUCCESS
} from "../actions/types";

const initialState = {
    objectsList: null,
    colorsList: null,
    selectedObjectsList: [],
    updatingSelectedObjects: false,
    isSubmitting: false,
    cameraIDList: [],
    savedSettings: [],
    polygons: [],
    cameraUUID: null,
};
const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_IMAGE_PENDING: {
            state.isSubmitting = true
            return {
                ...state
            }
        }

        case SUBMIT_IMAGE_SUCCESS: {
            state.isSubmitting = false
            return {
                ...state
            }
        }

        case SUBMIT_IMAGE_FAILURE: {
            state.isSubmitting = false
            return {
                ...state
            }
        }

        case CLEAR_ALL_OBJECT:
            state.selectedObjectsList = []
            return {
                ...state
            }

        case SET_OBJECT:
            state.selectedObjectsList = action.params || []
            return {...state}

        case ADD_REMOVE_OBJECT:

            if (state.selectedObjectsList.includes(action.params)) {
                state.selectedObjectsList = state.selectedObjectsList.filter(object => object !== action.params)
            } else {
                state.selectedObjectsList = [...state.selectedObjectsList, action.params]
            }
            return {
                ...state,
                updatingSelectedObjects: true,
            }

        case ADD_REMOVE_OBJECT_SUCCESS:
            return {
                ...state,
                updatingSelectedObjects: false,
            }

        // case SAVE_SELECTED_SETTING_SUCCESS:
        //     let tempSettings = state.savedSettings;
        //     tempSettings.push(action.data);
        //     return {
        //         ...state,
        //         selectedObjectsList: [],
        //         savedSettings: tempSettings,
        //     }

        case REMOVE_SELECTED_SETTING:
            return {
                ...state,
                updatingSelectedObjects: true,
            }

        case REMOVE_SELECTED_SETTING_SUCCESS:
            let tempSettingsRemoval = state.savedSettings;
            tempSettingsRemoval.splice(action.data, 1);
            return {
                ...state,
                updatingSelectedObjects: false,
                savedSettings: tempSettingsRemoval,
            }

        case SET_OPTIONS:
            return {
                ...state,
                objectsList: action.data.objects,
                colorsList: action.data.colors,
            }

        case SET_SAVED_SETTINGS:

            return {
                ...state,
                savedSettings: action.data
            }

        case SET_CAMERA_IDS:

            return {
                ...state,
                cameraIDList: action.data,
            }

        case SET_POLYGON_POINTS:
            return {
                ...state,
                polygons: action.params,
            }

        case SET_CAMERA_FOR_SETTINGS:
            return {
                ...state,
                selectedObjectsList : [],
                cameraUUID: action.params,
            }

        default:
            return state;
    }
}

export default settingsReducer