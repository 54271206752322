import {CLOSE_ADD_NEW_MODAL, OPEN_ADD_NEW_MODAL} from "../actions/types";

const initialState = {
    setAddNewModalOpen: false,
};

const modalReducer = (state = initialState, action) => {

    switch (action.type) {

        case OPEN_ADD_NEW_MODAL:

            return {
                ...state,
                setAddNewModalOpen: true,
            }

        case CLOSE_ADD_NEW_MODAL:

            return {
                ...state,
                setAddNewModalOpen: false,
            }

        default:
            return state;
    }
}

export default modalReducer