import { SET_DATE_RANGE_ACTION, SET_DATE_RANGE_CALENDAR_ACTION } from "../actions/types";
import moment from "moment";

const initialState = {
    startingDate: localStorage.getItem('start'),
    endingDate: localStorage.getItem('end'),
    endingDateEvents: localStorage.getItem('end'),
};

const controllerReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DATE_RANGE_ACTION:
            if (!localStorage.getItem('defaultWindow')) {
                localStorage.setItem('defaultWindow', 7);
            }
            return {
                ...state,
                startingDate: localStorage.getItem('start'),
                endingDate: localStorage.getItem('end'),
                endingDateEvents: localStorage.getItem('end'),
            };

        case SET_DATE_RANGE_CALENDAR_ACTION:
            localStorage.setItem('start', moment(action.params.startingDate).format('YYYYMMDD'));
            localStorage.setItem('end', moment(action.params.endingDate).format('YYYYMMDD'));
            return {
                ...state,
                startingDate: localStorage.getItem('start'),
                endingDate: localStorage.getItem('end'),
                endingDateEvents: localStorage.getItem('end'),
            };

        default:
            return state;
    }
}

export default controllerReducer
