import { CHECK_TOKEN_FINISH, CHECK_TOKEN_PENDING, GET_API_KEY_FINISH, GET_API_KEY_PENDING, LOG_IN, LOG_IN_FAILURE, LOG_IN_SUCCESS, SIGN_UP, SIGN_UP_FAILURE, SIGN_UP_SUCCESS } from "../actions/types";

const initialState = {
    signingUp: false,
    loggingIn: false,
    signUpError: null,
    logInError: false,
    checkingToken: false,
    checkingApiKey: true
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP:
            return {
                ...state,
                signingUp: true,
            }

        case SIGN_UP_SUCCESS:
            return {
                ...state,
                signingUp: false,
                signUpError: null
            }

        case SIGN_UP_FAILURE:
            return {
                ...state,
                signingUp: false,
                signUpError: action.data.message,
            }

        case LOG_IN:
            return {
                ...state,
                loggingIn: true,

            }

        case LOG_IN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                logInError: null,
            }

        case LOG_IN_FAILURE:
            return {
                ...state,
                loggingIn: false,
                logInError: action.data.message,
            }

        case CHECK_TOKEN_FINISH:
            return {
                ...state,
                checkingToken: false
            }

        case CHECK_TOKEN_PENDING:
            return {
                ...state,
                checkingToken: true
            }

        case GET_API_KEY_PENDING:
            return {
                ...state,
                checkingApiKey: true
            }

        case GET_API_KEY_FINISH:
            return {
                ...state,
                checkingApiKey: false
            }

        default:
            return state;
    }
}

export default authReducer