export const SET_DATE_RANGE_ACTION = 'SET_DATE_RANGE_ACTION';
export const SET_DATE_RANGE_CALENDAR_ACTION = 'SET_DATE_RANGE_CALENDAR_ACTION';

export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const SHOW_COPY_TOAST = 'SHOW_COPY_TOAST';

export const SHOW_SELECTED_TAB = 'SHOW_SELECTED_TAB';

export const SET_OBJECT = 'SET_OBJECT'

export const ADD_REMOVE_OBJECT = 'ADD_REMOVE_OBJECT';
export const ADD_REMOVE_OBJECT_SUCCESS = 'ADD_REMOVE_OBJECT_SUCCESS';

export const SAVE_SELECTED_SETTING = 'SAVE_SELECTED_SETTING';
export const SAVE_SELECTED_SETTING_SUCCESS = 'SAVE_SELECTED_SETTING_SUCCESS';
export const SAVE_SELECTED_SETTING_FAILURE = 'SAVE_SELECTED_SETTING_FAILURE';

export const REMOVE_SELECTED_SETTING = 'REMOVE_SELECTED_SETTING';
export const REMOVE_SELECTED_SETTING_SUCCESS = 'REMOVE_SELECTED_SETTING_SUCCESS';

export const GET_OPTIONS = 'GET_OPTIONS';
export const SET_OPTIONS = 'SET_OPTIONS';

export const GET_METADATA = 'GET_METADATA';
export const SET_METADATA = 'SET_METADATA';
export const SET_GRAPH = 'SET_GRAPH';

export const GET_SAVED_SETTINGS = 'GET_SAVED_SETTINGS';
export const SET_SAVED_SETTINGS = 'SET_SAVED_SETTINGS';

export const GET_CAMERA_IDS = 'GET_CAMERA_IDS';
export const SET_CAMERA_IDS = 'SET_CAMERA_IDS';

export const CLEAR_PROCESSED_IMAGES = 'CLEAR_PROCESSED_IMAGES'

export const GET_PROCESSED_IMAGES = 'GET_PROCESSED_IMAGES';
export const SET_PROCESSED_IMAGES = 'SET_PROCESSED_IMAGES';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';


export const SUBMIT_IMAGE_SETTING = 'SUBMIT_IMAGE_SETTING';
export const SUBMIT_IMAGE_SETTING_SUCCESS = 'SUBMIT_IMAGE_SETTING_SUCCESS';
export const SUBMIT_IMAGE_SETTING_FAILURE = 'SUBMIT_IMAGE_SETTING_FAILURE';

export const SUBMIT_IMAGE = 'SUBMIT_IMAGE';
export const SUBMIT_IMAGE_PENDING = 'SUBMIT_IMAGE_PENDING'
export const SUBMIT_IMAGE_SUCCESS = 'SUBMIT_IMAGE_SUCCESS';
export const SUBMIT_IMAGE_FAILURE = 'SUBMIT_IMAGE_FAILURE';

export const SUBMIT_IMAGE_TRIAL = 'SUBMIT_IMAGE_TRIAL';
export const SUBMIT_IMAGE_TRIAL_SUCCESS = 'SUBMIT_IMAGE_TRIAL_SUCCESS';
export const SUBMIT_IMAGE_TRIAL_FAILURE = 'SUBMIT_IMAGE_TRIAL_FAILURE';

export const OPEN_ADD_NEW_MODAL = 'OPEN_ADD_NEW_MODAL';
export const CLOSE_ADD_NEW_MODAL = 'CLOSE_ADD_NEW_MODAL';

export const GET_PROJECTS = 'GET_PROJECTS';
export const SET_PROJECTS = 'SET_PROJECTS';

export const SET_POLYGON_POINTS = 'SET_POLYGON_POINTS';

export const GET_CAMERAS = 'GET_CAMERAS';
export const SET_CAMERAS = 'SET_CAMERAS';

export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const CREATE_NEW_CAMERA = 'CREATE_NEW_CAMERA';

export const RESET_POLYGONS = 'RESET_POLYGONS';
export const SET_CAMERA_FOR_SETTINGS = 'SET_CAMERA_FOR_SETTINGS';

export const privateEmails = "gmail yahoo aol hotmail live outlook";

export const CLEAR_ALL_OBJECT = 'CLEAR_ALL_OBJECT'

export const SET_BLURRED_IMAGE = 'SET_BLURRED_IMAGE'

export const GET_API_KEY = "GET_API_KEY"
export const GET_API_KEY_PENDING = "GET_API_KEY_PENDING"
export const GET_API_KEY_FINISH = "GET_API_KEY_FINISH"

export const CHECK_TOKEN = "CHECK_TOKEN"
export const CHECK_TOKEN_PENDING = "CHECK_TOKEN_PENDING"
export const CHECK_TOKEN_FINISH = "CHECK_TOKEN_FINISH"

