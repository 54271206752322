import { HIDE_TOAST, SHOW_COPY_TOAST, SHOW_TOAST } from "../actions/types";

const initialState = {
    showToast: false,
    toastMessage: null,
    toastType: null,
};

const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_COPY_TOAST:

            return {
                ...state,
                showToast: true,
                toastType: "success",
                toastMessage: "Copied to clipboard"
            }

        case SHOW_TOAST:
            return {
                ...state,
                showToast: action.data.showToast,
                toastMessage: action.data.toastMessage,
                toastType: action.data.toastType,
            }

        case HIDE_TOAST:
            return {
                ...state,
                showToast: false,
                toastMessage: null,
                toastType: null,
            }

        default:
            return state;
    }
}

export default toastReducer