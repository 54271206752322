import { put } from 'redux-saga/effects';
import {
    ADD_REMOVE_OBJECT_SUCCESS,
    REMOVE_SELECTED_SETTING_SUCCESS,
    SET_CAMERA_IDS,
    SET_OPTIONS,
    SET_PROCESSED_IMAGES,
    SET_SAVED_SETTINGS,
    SUBMIT_IMAGE_FAILURE,
    SUBMIT_IMAGE_PENDING,
    SUBMIT_IMAGE_SUCCESS
} from "../actions/types";
import axios from 'axios';
import { map, get } from 'lodash'
import { ColorHex } from '../utils/constants';
import { toSnakeCase } from '../utils/helper';
import { toastError, toastSuccess } from '../utils/functions';
import { checkSession, getAccessToken } from './authSagas';


export const baseURL = process.env.REACT_APP_BACKEND_URL
export const apiURL = process.env.REACT_APP_API_URL

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.message === 'Network Error') {
        checkSession()
    } else {
        return error;
    }
});

export function* addRemoveObjectSaga() {
    yield put({ type: ADD_REMOVE_OBJECT_SUCCESS });
}

export function* saveSelectedSettingSaga(params) {
    try {
        const token = yield getAccessToken()
        let obj = {};
        obj.objects = params.params.selectedObjectsList;
        obj.color = params.params.chosenColor;
        obj.maskType = params.params.maskType;
        obj.opacity = params.params.blurPercent / 100;
        obj.cameraId = params.params.cameraID;

        yield axios.post(`${baseURL}/api/settings`, obj, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    } catch (e) {
        toastError('somethingWentWrong')
    }
}

export function* removeSelectedSettingSaga(params) {
    try {
        yield put({ type: REMOVE_SELECTED_SETTING_SUCCESS, data: params.params });
    } catch { }
}

export function* getOptionsSaga() {
    try {
        const token = yield getAccessToken()
        let getOptionsResponse = yield axios.get(`${baseURL}/api/options`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        if (getOptionsResponse.status === 200) {
            yield put({
                type: SET_OPTIONS, data: {
                    objects: getOptionsResponse.data.objects,
                    colors: Object.keys(ColorHex)
                }
            });
        }
        else {
            yield put({ type: SET_OPTIONS, data: { objects: [], colors: [] } });
        }
    } catch (e) {
        toastError('somethingWentWrong')
        yield put({ type: SET_OPTIONS, data: { objects: [], colors: [] } });
    }
}

export function* getSavedSettingsSaga() {
    try {
        const token = yield getAccessToken()
        let getSavedSettingsResponse = yield axios.get(`${baseURL}/api/settings`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (getSavedSettingsResponse.status === 200) {
            yield put({ type: SET_SAVED_SETTINGS, data: getSavedSettingsResponse.data.data });
        }
    } catch {
        toastError('somethingWentWrong')
    }
}


export function* getCameraIDsSaga() {
    try {
        const token = yield getAccessToken()
        let getCameraIDsResponse = yield axios.get(`${baseURL}/api/camera`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (getCameraIDsResponse.status === 200) {
            yield put({ type: SET_CAMERA_IDS, data: getCameraIDsResponse.data.data });
        }
    } catch {
        toastError('somethingWentWrong')
    }
}

export function* submitImageSettingSaga(params) {
    try {
        const token = yield getAccessToken()
        yield axios.put(`${baseURL}/api/settings`, params.params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        toastSuccess('settingUpdatedSuccessfully')
    } catch (e) {
        toastError('somethingWentWrong')
    }
}


export function* submitImageSaga(params) {
    const apiKey = localStorage.getItem('apiKey')

    if (!apiKey || apiKey === 'null') {
        toastError(`userDoNotHaveApiKey`)
        return
    }

    try {
        yield put({
            type: SUBMIT_IMAGE_PENDING,
        });
        yield put({ type: SET_PROCESSED_IMAGES, data: { imageUrl: "" } });
        const color = ColorHex[params.params.color]
        const classes = map(params.params.classes, object => toSnakeCase(object))
        const submitImageResponse = yield axios.post(`${apiURL}image?wait=true&prio=true`, { ...params.params, timeStamp: new Date(), color, classes }, {
            headers: {
                'X-API-Key': apiKey,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                "Authorization": ""
            }
        });

        if (submitImageResponse.status === 200) {
            toastSuccess('uploadImageSuccessfully')
            yield put({ type: SET_PROCESSED_IMAGES, data: { imageUrl: submitImageResponse.data.image } });
        }

        if (submitImageResponse.data.status === 'pending') {
            toastSuccess('imageIsProcessing')
        }

        yield put({
            type: SUBMIT_IMAGE_SUCCESS,
        });

    } catch (e) {
        toastError(get(e, 'message', 'somethingWentWrong.'))
        yield put({
            type: SUBMIT_IMAGE_FAILURE,
        });
    }
}

export function* submitImageTrialSaga(params) {
    try {
        yield axios.post(`${baseURL}/api/trial`, params.params);
    } catch (e) {
        if (e.message.includes('500')) {
            toastError('somethingWentWrong')
        }
    }
}
