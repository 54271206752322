import { CHECK_TOKEN, GET_API_KEY, LOG_IN, SIGN_UP } from "./types";

export const signUpAction = data => ({
    type: SIGN_UP,
    params: data
});

export const logInAction = data => ({
    type: LOG_IN,
    params: data
})

export const getApiKeyAction = data => ({
    type: GET_API_KEY,
    params: data
})

export const checkTokenAction = () => ({
    type: CHECK_TOKEN,
})